<template>
  <div id="select_mb">
    <van-notify v-model="notifyshow" :background="notifyColor" style="width:100%;">
      <span>{{notifySpan}}</span>
    </van-notify>

    <van-cell
      class="dateTime"
      center
      arrow-direction="left"
      is-link
      title="查询范围"
      :value="str_datetime"
      @click="show_dataTime = true"
    />
    <van-cell
      center
      arrow-direction="left"
      is-link
      title="时间间隔"
      :value="str_inter"
      @click="show_inter = true"
    />
    <van-cell
      center
      arrow-direction="left"
      is-link
      title="数据表源"
      :value="str_table"
      @click="show_tables = true"
    />

    
    <van-cell v-if="cur_s_table_type !== '1'"
      center
      arrow-direction="left"
      is-link
      title="筛选条件"
      :value="str_filte"
      @click="show_filte = true"
    />
    <div style="margin-bottom:1em;color:green;" v-else>
      说明:查询只显示有报警的数据行，1为触发报警
    </div>

    <van-popup v-model="show_dataTime" :style="{ width: '80%' }">
      <dateTime
        ref="dateTime"
        :dt="dt"
        @close_dateTimePopup="close_dateTimePopup"
      />
    </van-popup>
    <van-popup v-model="show_inter">
      <van-datetime-picker
        ref = "pDate"
        v-model="s_inter"
        type="time"
        :formatter="formatter"
        title="选择时间间隔"
        confirm-button-text = "  "
        cancel-button-text = "  "
        swipe-duration = "200"
      >
      </van-datetime-picker>

      <div id="time_mb_confirm">
        <van-button type="primary" @click="show_inter = false">确认</van-button>
      </div>
    </van-popup>

    <van-popup v-model="show_tables">
      <van-picker
        ref = "pTables"
        title="选择数据源"
        show-toolbar
        confirm-button-text="  "
        cancel-button-text="  "
        :columns="tables_name"
        swipe-duration = "200"
      />
      <div id="tables_mb_confirm">
        <van-button type="primary" @click="tables_onChange"
          >确认</van-button
        >
      </div>
    </van-popup>

    <van-popup v-model="show_filte" :close-on-click-overlay="false">
      <filte_mb
        ref="filte_mb"
        :cur_s_table="cur_s_table"
      />
    </van-popup>

    <van-button type="info" @click="update_record" style="width:30%"
      >查询</van-button
    >
  </div>
</template>

<style scoped>
#select_pc {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  padding: 10px;
}
.el-divider {
  margin: 6px 0px;
}
.s_col {
  height: 100%;
  align-items: center;
  min-width: 200px;
}
.el-date-editor--time {
  width: 100%;
}
.van-popup {
  width: 90%;
}
.van-cell {
  text-align: left;
  margin: 10px 0px;
}
.dateTime >>> .van-cell__value {
  text-align: left;
}
#time_mb_confirm >>> .van-button {
  width: 40%;
  margin: 10px 10px;
}
#tables_mb_confirm >>> .van-button {
  width: 40%;
  margin: 10px 10px;
}
</style>

<script>
/*import { Popup } from "vant";*/
/*import { Cell } from "vant";*/
/*import { Button } from "vant";*/
/*import { DatetimePicker } from "vant";*/
/*import { Picker } from "vant";*/
//import "vant/lib/popup/style";
//import "vant/lib/cell/style";
//import "vant/lib/button/style";
//import "vant/lib/datetime-picker/style";
//import "vant/lib/picker/style";
//-----------------------------------------------


/*import { Notify } from "vant";*/
import dateTime from "../components/dateTime_mb.vue";
import dateFormat from "../commonJS/dateFormat.js";
import filte_mb from "../components/filte_mb.vue";

export default {

  components: {
    dateTime,
    filte_mb,
    //[Popup.name]: Popup,
    //[Cell.name]: Cell,
    //[Button.name]: Button,
    //[Picker.name]: Picker,
    //[DatetimePicker.name]: DatetimePicker,
    /*[Notify.Component.name]: Notify.Component,*/
  },
  data() {
    return {
      s_inter: "00:05",
      cur_s_table: "",
      show_dataTime: false,
      show_inter: false,
      show_tables: false,
      show_filte: false,
      tables_name: [],
      str_table: "正在获取中...",
      str_filte:"无",
      filte_obj:{},
      cur_s_table_type:"",
      dt: {
        bt: new Date(new Date().setHours(0, 0, 0)),
        et: new Date(new Date().setHours(23, 59, 59)),
        //et: new Date(),
      },
      notifyshow:false,
      notifySpan:"",
      notifyColor:"#66CC66",
    };
  },
  props: ["tables", "allLabel"],
  methods: {
    update_record() {
      this.notifySpan = "正在获取..."      
      this.notifyColor = "#66CC66"
      this.notifyshow = true
      
      this.$parent.update_record({
        filte:[this.filte_obj],
        type:this.cur_s_table_type,
        bt: this.dt.bt,
        et: this.dt.et,
        s_inter: new Date(
          new Date().setHours(
            this.s_inter.substr(0, 2),
            this.s_inter.substr(3, 2),
            0
          )
        ),
        cur_s_table: this.cur_s_table,
      });
    },
    close_dateTimePopup(val) {
      this.show_dataTime = false;
    },
    formatter(type, val) {
      if (type === "hour") {
        return val + "时";
      }
      if (type === "minute") {
        return val + "分";
      }
      return val;
    },
    tables_onChange() {
    //tables_onChange(picker, value, index) {
      let index = this.$refs.pTables.getIndexes()
      this.str_table = this.tables_name[index];

      this.cur_s_table = this.tables[index].items;
      this.cur_s_table_type = this.tables[index].type

      this.show_tables = false
    },
  },
  computed: {
    str_datetime() {
      let bt = dateFormat.dateToStr("YYYY年mm月dd日HH时MM分", this.dt.bt);
      let et = dateFormat.dateToStr("YYYY年mm月dd日HH时MM分", this.dt.et);
      return bt + " 至 " + et;
    },
    str_inter() {
      return (
        this.s_inter.substr(0, 2) + "时" + this.s_inter.substr(3, 2) + "分"
      );
    },
  },
  watch: {
    allLabel() {
      //this.cur_s_table = this.allLabel.items;
      //this.str_table = this.allLabel.name;
      //this.tables_name.push(this.str_table);

      for (let tb of this.tables) {
        this.tables_name.push(tb.tname);
      }
      this.str_table = this.tables_name[0];
      this.cur_s_table = this.tables[0].items;
      this.cur_s_table_type = this.tables[0].type;
      //console.log('tables_name:',this.tables_name)
    },
  },
};
</script>
