<template>
  <div id="filte_mb">
    <div>选择筛选条件</div>
    <van-cell
      center
      arrow-direction="left"
      is-link
      title="数据项:"
      :value="str_label"
      @click="show_filte_label = true"
    />
    <van-cell
      center
      arrow-direction="left"
      is-link
      title="逻辑条件:"
      :value="str_logic"
      @click="show_filte_logic = true"
    />
    <van-cell-group>
      <van-field
        v-model="filte_obj.value"
        type="number"
        input-align="right"
        label="比较值"
        placeholder="请输入"
      />
    </van-cell-group>

    <div id="filte_mb_button">
      <van-button type="primary" @click="confirm">确认</van-button>
      <van-button type="primary" @click="reset">重置</van-button>
    </div>

    <van-popup v-model="show_filte_label">
      <van-picker
        title="选择数据项"
        ref="filte_label"
        show-toolbar
        :columns="cur_s_table"
        confirm-button-text="  "
        cancel-button-text="  "
        @cancel="show_filte_label = false"
        @confirm="show_filte_label = false"
        swipe-duration = "200"
      />
      <div>
        <van-button type="primary" @click="filte_label_confirm"
          >确认</van-button
        >
      </div>
    </van-popup>

    <van-popup v-model="show_filte_logic">
      <van-picker
        title="选择逻辑条件"
        ref="filte_logic"
        show-toolbar
        :columns="filte_logic_option"
        confirm-button-text="  "
        cancel-button-text="  "
        @cancel="show_filte_label = false"
        @confirm="show_filte_label = false"
        swipe-duration = "200"
      />
      <div>
        <van-button type="primary" @click="filte_logic_confirm"
          >确认</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<style scoped>
.van-cell {
  text-align: left;
  margin: 10px 0px;
}

#filte_mb {
  background-color: #e9eef3;
  height: 500px;
}

.van-picker {
  width: 100%;
}

#filte_mb_button {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

#filte_mb_button .van-button {
  flex-basis: 40%;
}
</style>

<script>
import { Toast } from "vant";
//import { defineComponent } from '@vue/composition-api'

export default {
  components: {
    Toast,
  },
  data() {
    return {
      filte_obj: { ifixLabel: "", logic: "", value: "" },
      show_filte_label: false,
      show_filte_logic: false,
      str_label: "请选择",
      str_logic: "请选择",
      //table:["aaaaa","bbbbb"]
      //table:[{text:"1111",value:"aaaaaa"},{text:"22222",value:"bbbbbb"}]
      filte_logic_option: [
        { text: "大于", value: ">" },
        { text: "小于", value: "<" },
        { text: "等于", value: "=" },
      ],
    };
  },
  props: ["cur_s_table"],
  mounted() {
    //this.show_filte = this.$parent.$parent.show_filte
    //console.log(this.cur_s_table);
  },
  methods: {
    confirm() {
      let ifixLabel = Boolean(this.filte_obj.ifixLabel);
      let logic = Boolean(this.filte_obj.logic);
      let value = Boolean(this.filte_obj.value !== "");

      if (ifixLabel && logic && value) {
        this.$parent.$parent.show_filte = false;
        this.$parent.$parent.filte_obj = this.filte_obj;
        this.$parent.$parent.str_filte =
          this.str_label + " " + this.str_logic + " " + this.filte_obj.value;
      } else if (!(ifixLabel || logic || value)) {
        this.$parent.$parent.show_filte = false;
        this.$parent.$parent.filte_obj = this.filte_obj;
        this.$parent.$parent.str_filte = "无";
      } else {
        Toast("每项必填,或重置取消");
      }
    },
    reset() {
      this.filte_obj = { ifixLabel: "", logic: "", value: "" };
      this.str_label = "请选择";
      this.str_logic = "请选择";
      this.$parent.$parent.show_filte = false;
      this.$parent.$parent.str_filte = "无";
      this.$parent.$parent.filte_obj = {};
    },
    filte_label_confirm() {
      this.str_label = this.$refs.filte_label.getValues()[0].text;
      this.filte_obj.ifixLabel = this.$refs.filte_label.getValues()[0].ifixLabel;
      this.show_filte_label = false;
    },
    filte_logic_confirm() {
      this.str_logic = this.$refs.filte_logic.getValues()[0].text;
      this.filte_obj.logic = this.$refs.filte_logic.getValues()[0].value;
      this.show_filte_logic = false;
      //console.log(this.filte_obj);
    },
  },
};
</script>
