<template>
  <div id="dateTime_mb">
      <van-datetime-picker
        v-model="dt.bt"
        type="datetime"
        title="选择开始时间"
        :formatter="formatter"
        visible-item-count=4
        item-height="2.8rem"
        confirm-button-text = "  "
        cancel-button-text = "  "
        swipe-duration = "200"
        
      >
        <a slot="confirm" style="display:none"></a>
        <a slot="cancel" style="display:none"></a>
      </van-datetime-picker>
      <br />
      <van-datetime-picker
        v-model="dt.et"
        type="datetime"
        title="选择结束时间"
        :formatter="formatter"
        visible-item-count=4
        item-height="2.8rem"
        confirm-button-text = "  "
        cancel-button-text = "  "
        swipe-duration = "200"
      >
        <a slot="confirm" style="display:none"></a>
        <a slot="cancel" style="display:none"></a>
      </van-datetime-picker>

      <div id="datetime_mb_confirm">
        <van-button type="primary" @click="datetime_confirm">确认</van-button>
      </div>
  </div>
</template>

<style scoped>

#dateTime_mb{
height:100%;
}


#datetime_mb_confirm {
  width: 100%;
}

#datetime_mb_confirm >>> .van-button {
  width: 40%;
  margin: 10px 10px;
}
</style>

<script>
//import { DatetimePicker } from "vant";
//import { Button } from "vant";

//import "vant/lib/datetime-picker/style";
//import "vant/lib/button/style";

export default {
  components: {
   // [DatetimePicker.name]: DatetimePicker,
  },
  data() {
    return {
      //bt: new Date(new Date().setHours(0, 0, 0)),
      //et: new Date(),
      show:true,
    };
  },
  props:['dt'],
  methods: {
    formatter(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "时";
      }
      if (type === "minute") {
        return val + "分";
      }
      return val;
    },
    datetime_confirm(){
        this.$emit("close_dateTimePopup",false)

    }
  },
};
</script>
